<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr46_hero.png" alt="Ken Laudano named SVP – Head of Lender Finance">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr46_small.png" alt="Ken Laudano named SVP – Head of Lender Finance">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">WebBank Announces Ken Laudano named SVP – Head of Lender Finance</h2>
                        <br>
                        <p><em><small>February 11, 2025</small></em></p>
                        <br>
                        <p><strong>SALT LAKE CITY</strong> -- WebBank is pleased to announce the appointment of Ken Laudano as Senior Vice President – Head of Lender Finance. In this capacity, Mr. Laudano will be responsible for growing the Bank’s asset-backed lending business.  Under Ken’s leadership, the Lender Finance team will focus on providing debt capital to specialty finance originators and specialty finance fund managers, as well as fund managers of middle market corporate loans.  The team will specialize in standard asset-backed warehouse facilities as well as back-leverage facilities.  In addition, for direct loans, the Bank will provide administrative agent services to support larger syndicated facilities.  Mr. Laudano envisions expanding the Bank’s footprint beyond the traditional consumer and small business asset classes into newer, niche specialty finance asset classes that can benefit from his substantial industry experience and expertise.  Ken also seeks to capitalize on WebBank’s well-established position in the FinTech space as the Bank expands its Lender Finance business.</p>
                        <p>Mr. Laudano has over two decades of experience in the asset finance industry, with a strong background in transaction structuring and execution, cash flow modeling, and portfolio management. Prior to joining WebBank, he served as Senior Vice President and Deputy Chief Credit Officer for Structured Finance and Private Equity at East West Bank. His career also includes significant roles at institutions such as Moody’s Investors Service, Morgan Stanley, BNY Mellon, and Credit Suisse.</p>
                        <p>Mr. Laudano holds an M.S. in Statistics and Financial Engineering from New York University and a B.A. in Economics from the University of Connecticut. He is a Chartered Alternative Investment Analyst (CAIA) and a Financial Data Professional (FDP).</p>
                        <p>"Ken's appointment comes at a pivotal time as we expand our Lender Finance operations," said Jason Lloyd, President & CEO of WebBank. "His extensive experience and strategic vision will be instrumental in enhancing our asset-backed lending capabilities and driving our growth initiatives forward."</p>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank has originated and funded over $218 billion in consumer and commercial credit products.  As “The Bank Behind the Brand®”, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance companies, software as a service (SaaS) and financial technology (FinTech) companies.  The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded finance with Strategic Partner platforms.  WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance products through its wholly owned subsidiary National Partners. For more information, please visit <a href="https://webbank.com">webbank.com</a>.</p>
                        <br>

                        <p><strong>Contact:</strong>
                            <br>Shannon Hutchings
                            <br><a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a>
                        </p>
                        <br>
                    </div>

                    <div class=" modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website
                                    and does not represent either the linked website or you, should you enter into a
                                    transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked
                                    site. We encourage you to review their privacy and security policies which may differ
                                    from WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Ken Laudano named SVP – Head of Lender Finance";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
