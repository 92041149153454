<template>
  <section class="hero newsroom-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
    <div class="hero-body">
      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-8">
              <div class="deposit-products-box">
                <p class="title hero-title">
                  Newsroom
                </p>
                <p class="subtitle hero-subtitle">
                  Get the latest news about WebBank, our partners and the products we offer right here.
                </p>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  </section>

  <div class="blue-bg is-hidden-widescreen">
    <img src="https://assets.webbank.com/newsroom-hero.png">
    <section class="section">
      <div class="container">
        <p class="title hero-title-mobile">
          Newsroom
        </p>
        <br>
      </div>
    </section>
  </div>

  <section class="section grey-bg">
    <div class="container">

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR46-2025-02-11">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr46_small.png" alt="WebBank Announces Ken Laudano named SVP – Head of Lender Finance">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Announces Ken Laudano named SVP – Head of Lender Finance</p>
                        <br>
                        <br>
                        <time datetime="2025-02-11" class="news-date">February 11th, 2025</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR45-2024-12-05">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr45_small.png" alt="National Partners Adds Input 1's Technology Stack">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">National Partners Adds Input 1's Technology Stack to their Roster to Streamline Operations for Insurance Agents and Carriers</p>
                        <br>
                        <br>
                        <time datetime="2024-08-06" class="news-date">December 5th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR44-2024-08-06">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr44_small.png" alt="ADDITION OF MALIN ERIKSSON TO WEBBANK BOARD OF DIRECTORS">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">BMG Money and WebBank Join Forces to Expand Financial Inclusion Nationwide</p>
                        <br>
                        <br>
                        <time datetime="2024-08-06" class="news-date">August 6th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR42-2024-06-04">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr42_small.png" alt="ADDITION OF MALIN ERIKSSON TO WEBBANK BOARD OF DIRECTORS">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Announces the Addition of Malin Eriksson to its Board of Directors</p>
                        <br>
                        <br>
                        <time datetime="2024-06-04" class="news-date">June 4th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR41-2024-05-22">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr41_small.png" alt="Empower to Acquire Petal">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Announces Ken Laudano named SVP – Head of Credit Structuring &amp; Counterparty Risk</p>
                        <br>
                        <br>
                        <time datetime="2024-05-22" class="news-date">May 22nd, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR43-2024-05-16">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr43_small.png"
                        alt="BEST PLACES TO WORK IN FINANCIAL TECHNOLOGY 2024">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank, a Steel Partners Company, Honored as one of American Banker's Best Places to Work in Financial Technology 2024</p>
                        <br>
                        <br>
                        <time datetime="2024-05-16" class="news-date">May 16th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR40-2024-04-17">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr40_small.png" alt="New and improved Klarna Card">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">New and improved Klarna Card announced in the US, empowering smarter
                            spending and greater financial control</p>
                        <br>
                        <br>
                        <time datetime="2024-04-17" class="news-date">April 17th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR39-2024-04-09">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr39_small.png" alt="Empower to Acquire Petal">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Empower Signs Definitive Agreement to Acquire Petal</p>
                        <br>
                        <br>
                        <time datetime="2024-04-09" class="news-date">April 9th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR38-2024-03-27">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr38_small.png"
                        alt="Erik Cannon named SVP – Chief Operations Officer">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Erik Cannon named SVP – Chief Operations Officer</p>
                        <br>
                        <br>
                        <time datetime="2024-02-27" class="news-date">March 27th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR37-2024-03-27">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr37_small.png"
                        alt="Steve Stanford named EVP – Chief Financial Officer">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Steve Stanford named EVP – Chief Financial Officer</p>
                        <br>
                        <br>
                        <time datetime="2024-02-27" class="news-date">March 27th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR36-2024-02-27">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr36_small2.png" alt="Collective Liquidity">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Collective Liquidity Launches Zero Interest Option Exercise Loans</p>
                        <br>
                        <br>
                        <time datetime="2024-02-27" class="news-date">February 27th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR35-2024-01-29">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr35_small.png" alt="Glassdoor Best Place to Work">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Honored as One of the Best Places to Work in 2024</p>
                        <br>
                        <br>
                        <time datetime="2024-01-29" class="news-date">January 29th, 2024</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>
      <!-- comment to invalidate cache... delete me -->

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR34-2023-10-02">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr34-small.png"
                        alt="Collective Liquidity and Aliya Capital">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Collective Liquidity Closes Financing Round and Partners with Aliya
                          Capital Partners</p>
                        <br>
                        <br>
                        <time datetime="2023-10-02" class="news-date">October 2nd, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR33-2023-09-12">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr33-small.png" alt="Zip Primer Partnership">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Zip Partners with Global Payments Leader Primer to Accelerate U.S. Growth
                          and Market Share</p>
                        <br>
                        <br>
                        <time datetime="2023-09-12" class="news-date">September 12th, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR30-2023-06-11">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr30-small.png" alt="WebBank Honored">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Honored as One of the Best Places to Work in Financial Technology
                          2023</p>
                        <br>
                        <br>
                        <time datetime="2023-06-11" class="news-date">June 11th, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/build-PR29-2023-05-23">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr29_small.png" alt="PROG Holdings Announces Build">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">PROG Holdings Announces Build, an
                          Innovative Credit Building Financial
                          Management Tool</p>
                        <br>
                        <br>
                        <time datetime="2023-05-23" class="news-date">May 23rd, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/lane-health-apex-PR28-2023-05-22">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr28_small.png" alt="Lane Health APEX Award">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Lane Health Extends Streak of Industry Recognition with Third
                          Consecutive APEX Award</p>
                        <br>
                        <br>
                        <time datetime="2023-05-22" class="news-date">May 22nd, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/webbank-PR31-2023-07-26">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr31-small.png"
                        alt="Financial Technology Association">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Joins the Financial Technology Association</p>
                        <br>
                        <br>
                        <time datetime="2023-07-25" class="news-date">July 25th, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <newsroom-pagination></newsroom-pagination>


    </div>
  </section>

</template>

<script>
import NewsroomPagination from './Components/NewsroomPagination.vue';
export default {
    components: { NewsroomPagination },

    created() {
        document.title = "Newsroom - WebBank";
    },

}
</script>
